import Navbar from "../navbar";
import Header from "../header";
import Footer from "../footer";

function Location() {
  return (
    <div>
      <Navbar />
      <Header />
      <div>BODY</div>
      <Footer />
    </div>
  );
}

export default Location;
